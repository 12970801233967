import logo from "../assets/logo.png";

export const Navbar = () => {
  return (
    <div className="navbar-container">
      <div className="navbar-logo" onClick={() => window.scrollTo(0, 0)}>
        <img src={logo} alt="" />
        <h1>naima.world</h1>
      </div>
    </div>
  );
};
